<template>
  <div>
    <div 
      class="input row2"
    >
      <div 
        class="col" 
        :class="{ error: $v.form.area.$error }"
      >
        <Label 
          text="ขนาดพื้นที่ (ตารางเมตร)" 
          :required=true
        />
        <input 
          v-model.trim="form.area" 
          type="number" 
          step=".01"
        >
      </div>

      <div 
        class="col"
      >
        <Label text="ขนาดถนนหน้าโครงการ (ถ้ามี)" />
        <input 
          type="text" 
          v-model.trim="form.road_area"
        >
      </div>
    </div>
    <!-- area row -->

    <div 
      class="input" 
      :class="{ error: $v.form.current_step.$error }"
    >
      <Label 
        text="ระบุความพร้อมเพื่อใช้ดำเนินงาน" 
        :required=true
      />
      <ReadinessDropdown
        v-model="form.current_step" 
      />
    </div>

    <div class="input">
      <Label text="สาธารณูปโภคภายในโครงการ" />
      <span class="input-checkbox">
        <input 
          type="checkbox" 
          v-model="form.facility_plumbing"
        > 
        ประปา
      </span>
      <span class="input-checkbox">
        <input 
          type="checkbox" 
          v-model="form.facility_electricity"
        >
        ไฟฟ้า
      </span>
      <span class="input-checkbox">
        <input 
          type="checkbox" 
          v-model="form.facility_internet"
        >
        อินเตอร์เน็ต
      </span>
    </div>

    <div class="input">
      <Label text="มี BOQ หรือไม่ ?" />
      <span class="input-checkbox">
        <input type="checkbox" v-model="form.has_boq"> 
        ใช่, เรามี BOQ แล้ว
      </span>
    </div>
    
    <div class="input">
      <Label text="มีแบบบ้าน/โครงการอยู่แล้วหรือไม่ ?" />
      <span class="input-checkbox">
        <input type="checkbox" v-model="form.has_plan"> 
        ใช่, เรามีแบบบ้าน/โครงการแล้ว
      </span>
    </div>


    <div class="input">
      <Label text="โครงการหมู่บ้านหรือไม่ ?" />
      <span class="input-checkbox">
        <input type="checkbox" v-model="form.estate"> 
        ไช่, โครงการของเราเป็นโครงการหมู่บ้าน
      </span>
    </div>
  </div>
</template>

<script>
// Load library and variables
const {required, numeric} = require('vuelidate/lib/validators')

// Load components
import Label              from '@/components/common/form/Label.vue';
import ReadinessDropdown  from '@/components/project/form/input/ReadinessDropdown.vue';

// Load CSS
import '@/assets/css/project/project-form.css';


export default {
  props: {
    project: Object
  },
  data() {
    return {
      form: {
        area:         '',
        road_area:    '',
        current_step: '',
        facility_plumbing:    0,
        facility_electricity: 0,
        facility_internet:    0,
        has_boq:  0,
        has_plan: 0,
        estate:   0,
      }
    }
  },

  /**
   * Validate form data before save to api
   */
  validations: {
    form: {
      area: {
        required,
        numeric,
      },
      current_step: {required},
    }
  },

  /**
   * Registration components
   */
  components: {
    Label,
    ReadinessDropdown,
  },

  /**
   * Initial this component when created
   */
  created() {
    if(this.project !== undefined) {
      this.form = this.project;
    }
  },
}
</script>