<template>
  <div>
    <div 
      class="input" 
      :class="{ error: $v.form.address.$error }"
    >
      <Label 
        text="ที่อยู่" 
        :required=true
      />
      <input 
        type="text"
        v-model="form.address"
      >
    </div>
    
    <div 
      class="
        input 
        row2
      "
    >
      <div 
        class="col" 
        :class="{ error: $v.form.subdistrict.$error }"
      >
        <Label 
          text="ตำบล" 
          :required=true
        />
        <input 
          type="text"
          v-model="form.subdistrict"
        >
      </div>

      <div 
        class="col" 
        :class="{ error: $v.form.district.$error }"
      >
        <Label 
          text="อำเภอ" 
          :required=true
        />
        <input 
          type="text"
          v-model="form.district"
        >
      </div>
    </div>

    <div 
      class="
        input 
        row2
      "
    >
      <div 
        class="col"
        :class="{ error: $v.form.province.$error }"
      >
        <Label 
          text="จังหวัด" 
          :required=true
        />
        <select
          v-model="form.province"
        >
          <option value="">- เลือกจังหวัด -</option>
          <option v-for="p in province" :key="p" :value="p">{{ p }}</option>
        </select>
      </div>

      <div 
        class="col"
        :class="{ error: $v.form.zipcode.$error }"
      >
        <Label 
          text="รหัสไปรษณีย์" 
          :required=true
        />
        <input 
          v-model="form.zipcode"
          type="text" 
          maxlength="5"
        >
      </div>
    </div>
  </div>
</template>

<script>
// Load library and variables
import {province_th} from '@/variables/province.js';
const {required, numeric, minLength} = require('vuelidate/lib/validators')

// Load components
import Label              from '@/components/common/form/Label.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  props: {
    item: Object
  },
  data() {
    return {
      province_th: [],

      form: {
        address:      null,
        subdistrict:  null,
        district:     null,
        province:     '',
        zipcode:      null,
      }
    }
  },

  /**
   * Validate form data before save to api
   */
  validations: {
    form: {
      address: {required},
      subdistrict: {required},
      district: {required},
      province: {required},
      zipcode: {
        required,
        numeric, 
        minLength: minLength(5)
      }
    }
  },

  /**
   * Registration components
   */
  components: {
    Label,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.province = province_th;
    
    if(this.item !== undefined) {
      this.form = this.item;
    }
  },
}
</script>